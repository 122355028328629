@import "../../styles/helpers";
.zero,
.negative,
.positive {
    display: inline-flex;
    align-items: center;
    @include caption2;
    font-size: 18px;
    svg {
        margin-right: 4px; } }

.negative {
    color: $p3;
    svg {
        fill: $p3; } }
.zero {
    color: $p1;
    svg {
        fill: $p1; } }

.positive {
    color: $p2;
    svg {
        fill: $p2; } }

.background {
    &.zero,
    &.positive,
    &.negative {
        height: 24px;
        padding: 0 4px;
        border-radius: 8px; }
    &.positive {
        background: #EAFAE5;
        @include dark {
            background: rgba(#EAFAE5, .25); } }
    &.zero {
        background: #e5f6fa;
        @include dark {
            background: rgba(#e5f6fa, .25); } }
    &.negative {
        background: #FFE7E4;
        @include dark {
            background: rgba(#FFD8D3, .15); } } }
