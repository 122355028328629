@import "styles/helpers";

.head {
    margin-bottom: 44px;
    @include t {
        margin-bottom: 32px; }
    @include m {
        flex-wrap: wrap;
        margin-bottom: 24px;
        .title {
            width: 100%;
            margin: 0 0 16px; } } }

.button {
    margin-left: 16px; }
.query {
     margin-right: 16px;
     input {
        height: 41px!important; } }
.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: none; } }

.col {
    display: table-cell;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $n3;
    @include caption2;
    font-weight: 500;
    color: $n4;
    @include dark {
        border-color: $n6; }
    &:not(:last-child) {
        padding-right: 24px; } }

.foot {
    margin-top: 20px;
    text-align: center;
    @include t {
        margin-top: 0; }
    .button {
        span {
            // color: $n1
            @include dark {
                color: $n1; } }

        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.outer {
    max-width: 560px; }
.filters {
    margin-right: 20px; }
.faq {
    margin-bottom: 12px;
    display: block;
    .question {
        z-index: 5;
        width: 100%;
        min-height: 54px;
        background: $n1;
        border: 1px solid $n6;
        padding: 8px;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        span {
            cursor: pointer; }
        svg {
            cursor: pointer; }
        @include dark {
            background: $n6;
            border: 1px solid $n6;
            svg {
                fill: $n2; } } }
    .answer {
        z-index: 0;
        width: 100%;
        height: 0px;
        opacity: 0;
        background: $n1;
        border: 1px solid $n6;
        padding: 8px;
        border-radius: 0px 0px 12px 12px;
        display: none;
        justify-content: space-between;
        align-items: center;
        @include dark {
            background: $n6;
            border: 1px solid $n6;
            svg {
                fill: $n2; } } } }
.active {
    .question {
        border-radius: 12px 12px 0px 0px;
        border-bottom: none; }
    .answer {
        opacity: 1;
        height: auto;
        display: flex;
        transition: all 300ms linear; } }
