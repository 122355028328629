@import "../../../styles/helpers";
.foot {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 20px;
    .active {
        color: $p2; }
    .button_red {
        background: #fe6f5ce1;
        &:hover {
            background: #f62f14; } } }
.card {
    // flex: 1
    background: $n1;
    @include dark {
        background: $n7; }
    flex: 0 0 calc(50% - 20px);
    @include t {
        flex: 0 0 calc(100%); }

    margin-bottom: 20px!important;
    margin-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 31px;
    padding-right: 0px;
    border-radius: 15px 20px 20px 15px;
    @include m {
        flex: 0 0 calc(100%);
        border-radius: 15px 20px 20px 15px;
        padding-left: 0px; }
    .info {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        svg {
            fill: $p3; }
        span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.02em;
            color: $p3;
            margin-top: 12px; } }

    .win {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #FFBC99;
        margin-top: 12px;
        margin-bottom: 12px; }
    .qrcode {
        display: flex;
        flex-direction: row;
        justify-content: center;
        .preview {
            flex-shrink: 0;
            width: 170px;
            height: 170px;
            margin-right: 27px;
            margin-left: 27px;
            border-radius: 18px;
            overflow: hidden;
            svg {
                width: 100%;
                height: 100%;
                object-fit: cover; } } }
    .await {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .text {
            margin-top: 12px;
            display: flex;
            color: #504f4e;
            // color: #FFBC99
            justify-content: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: -0.02em;
            .time {
                color: #FFBC99;
                font-size: 12px;
                line-height: 12px; }
            .btn {
                padding: 0px 5px;
                background: $p3;
                cursor: pointer;
                border-radius: 12px;
                color: $n2; } }
        .line {
            margin-top: 12px;
            height: 2px;
            background: #FFBC99; } } }
