@import "../../../styles/helpers";
.card {
    // flex: 1
    background: $n1;
    @include dark {
        background: $n7; }
    flex: 0 0 calc(50% - 20px);
    @include t {
        flex: 0 0 calc(100%); }

    margin-bottom: 20px!important;
    margin-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 31px;
    padding-right: 0px;
    border-radius: 15px 20px 20px 15px;
    @include m {
        flex: 0 0 calc(100%);
        border-radius: 15px 20px 20px 15px;
        padding-left: 0px; }
    .info {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        svg {
            fill: $p3; }
        span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.02em;
            color: $p3;
            margin-top: 12px; } }
    // max-height: 298px
    .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1;
        padding-top: 24px;
        padding-bottom: 27px;
        @include m {
            padding-left: 23px; }
        .top {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            // +m
            //     flex-direction: column
            //     justify-content: center
            .win {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: -0.02em;
                color: #FFBC99;
                margin-top: 12px; }
            .type {
                margin-top: 12px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                letter-spacing: -0.02em; }
            .await {
                margin-top: 18px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                .text {
                    margin-top: 12px;
                    display: flex;
                    color: #504f4e;
                    // color: #FFBC99
                    justify-content: center;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    letter-spacing: -0.02em;
                    .time {
                        color: #FFBC99;
                        font-size: 12px;
                        line-height: 12px; }
                    .btn {
                        padding: 0px 5px;
                        background: $p3;
                        cursor: pointer;
                        border-radius: 12px;
                        color: $n2; } }
                .line {
                    margin-top: 12px;
                    height: 2px;
                    background: #FFBC99; } } } }

    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #dcdcdc;
        @include dark {
            background: #302D2D; }
        border-radius: 0px 20px 20px 0px;
        @include m {
            border-radius: 0px 0px 20px 20px;
            min-height: 64px; }
        .enter {
            color: #FF6A55;
            transform: rotate(-90deg);
            @include m {
                transform: rotate(0deg); } } }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 20px; }
    .title {
        // flex-grow: 1
        // padding-right: 16px
        transition: color .2s; }
    .stage {
        color: $n4;
        margin-top: 9px; }

    .preview {
        flex-shrink: 0;
        width: 170px;
        height: 170px;
        margin-right: 27px;
        border-radius: 18px;
        overflow: hidden;
        svg {
            width: 100%;
            height: 100%;
            object-fit: cover; } } }
.post {
    .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
        .title {
            // flex-grow: 1
            // padding-right: 16px
            transition: color .2s; }
        .stage {
            color: $n4;
            margin-top: -5px;
            font-style: normal;
            font-weight: 300;
            font-size: 20px; } }
    .card {
        margin-bottom: 24px;
        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border: 1px solid $n3;
            border-radius: 12px;
            padding: 12px;
            cursor: pointer; }
        .item_center {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 70px;
                letter-spacing: -0.02em;
                margin-bottom: 4px; } } } }
.foot {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 20px; }
.active {
    color: $p2; }
