@import "../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100%);
        width: calc(100%);
        padding-right: 0px; } }

.card {
    padding: 0px;
    background: #FCFCFC;
    border-radius: 14px;
    &:not(:last-child) {
        margin-bottom: 20px; } }
.header_ui {
    // margin-right: 18px
    margin-bottom: 6px!important;
    padding: 12px;
    background: #FCFCFC;
    border-radius: 14px;
    img {
        border-radius: 14px;
        @include m {
            width: 100%;
            object-fit: cover; } }
    @include dark {
        background: $n7; } }
.footer_ui {
    // margin-right: 18px
    padding: 12px;
    background: #FCFCFC;
    border-radius: 14px;
    img {
        border-radius: 14px;
        @include m {
            width: 100%;
            object-fit: cover; } }
    @include dark {
        background: $n7; } }
.foot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    margin-bottom: 22px;
    button {
        border: 1px solid $n7;
        @include dark {
            border: 1px solid $n1; } } }
.filters {
    // width: 100%
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 14px
    margin-top: 14px;
    margin-bottom: 18px;
    padding: 12px;
    background: #FCFCFC;
    border-radius: 14px;
    @include dark {
        background: $n7; }
    .button {
        margin: 0px 8px; }
    @include m {
        // flex-direction: column
        flex-wrap: wrap;
        // display: flex
        .button {
            // display: flex
            flex: 0 0 calc(50% - 8px);
            margin: 6px 4px;
            width: calc(50% - 8px); } } }
.buttonActive {
    // border: 1px solid $n7!important
    box-shadow: 0 0 0 2px $n8 inset;
    svg {
        fill: $n8; }
    @include dark {
        // border: 1px solid $n1!important
        box-shadow: 0 0 0 2px $n1 inset;
        svg {
            fill: $n1; } } }
