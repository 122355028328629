@import "../../styles/helpers";
.uploadpage {
    .uploader {
        // border: 2px dashed #cdcdd7
        display: flex;
        justify-content: center;
        position: relative; }
    img {
        width: 20px;
        height: 16px;
        align-self: center;
        display: inline-flex; }
    span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #000000; }

    .deleteIcon {
        // position: absolute
        z-index: 2;
        width: 32px !important;
        height: 32px !important; } }
