@import "../../../../../styles/helpers";

.control {
    display: flex;
    align-items: center; }

.button {
    flex-shrink: 0;
    // width: 36px
    // height: 36px
    border-radius: 50%;
    background: transparent;

    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } }
    &:not(:last-child) {
        margin-right: 16px; } }





