// common styles
body {
    min-width: 375px;
    background: $n2;
    @include inter;
    @include base1-s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: -.01em;
    color: $n7;
    @include dark-body {
        background: $n8;
        color: $n1; } }

a {
    text-decoration: none; }

svg,
img {
    vertical-align: middle; }

button {
    background: none; }

button,
input,
textarea {
    @include inter; }

.desktop {
    &-hide {
        @include d {
            display: none !important; } }
    &-show {
        display: none !important;
        @include d {
            display: block !important; } }
    &-text-right {
        @include d {
            text-align: right; } } }

.tablet {
    &-hide {
        @include t {
            display: none !important; } }
    &-show {
        display: none !important;
        @include t {
            display: block !important; } } }

.mobile {
    &-hide {
        @include m {
            display: none !important; } }
    &-show {
        display: none !important;
        @include m {
            display: block !important; } } }

.recharts-rectangle.recharts-tooltip-cursor {
    @include dark-common {
        fill: $n6; } }

.recharts-curve.recharts-tooltip-cursor {
    @include dark-common {
        stroke: $n5; } }

.recharts-sector {
    @include dark-common {
        stroke: $n7; } }
.white {
    color: $n1;
    @include dark-common {
        color: $n1; } }
.expired {
    background: $p32;
    div {
        border-bottom: none; } }
.suspend {
    background: $p54;
    div {
        border-bottom: none; } }
.suspend-light {
    background: $p52;
    div {
        border-bottom: none; } }
.mr-4 {
    margin-right: 4px; }
.mr-6 {
    margin-right: 6px; }
.mr-8 {
    margin-right: 8px; }
.mr-10 {
    margin-right: 10px; }
.mr-12 {
    margin-right: 12px; }
.mr-14 {
    margin-right: 14px; }
.mr-16 {
    margin-right: 16px; }
.ml-4 {
    margin-left: 4px; }
.ml-6 {
    margin-left: 6px; }
.ml-8 {
    margin-left: 8px; }
.ml-10 {
    margin-left: 10px; }
.ml-12 {
    margin-left: 12px; }
.ml-14 {
    margin-left: 14px; }
.ml-16 {
    margin-left: 16px; }
.link {
    color: #2A85F0!important; }

