@import "../../styles/helpers";

.textarea {
    width: 100%;
    height: 132px;
    padding: 8px 10px;
    border-radius: 12px;
    border: 2px solid transparent;
    background: $n2;
    @include inter;
    @include base1-s;
    color: $n7;
    transition: all .2s;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $n1; }
    @include placeholder {
        color: $n4;
        @include dark {
            color: $shades1; } }
    &:focus {
        border-color: $shades1;
        background: $n;
        @include dark {
            border-color: $n5;
            background: $n8; } } }
