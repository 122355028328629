@import "../../../../styles/helpers";

.title {
    margin-bottom: 24px; }

.info {
    margin-bottom: 12px;
    @include title1-s; }

.preview {
    img {
        width: 100%;
        border-radius: 8px; } }

.foot {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $n3;
    @include dark {
        border-color: $n6; } }

.field {
    margin-top: 8px; }

.field_flex {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        &:first-child {
            // margin-top: 0.8px
            flex: 0 0 50%; } } }
.field_checkbox {
    margin-top: 12px;
    display: flex;
    justify-content: start;
    align-items: center;
    label {
        span {
            &:first-child {
                width: 20px;
                height: 20px;
                &::before {
                    width: 12px;
                    height: 8px; } }
            span {
                padding-left: 6px;
                padding-right: 6px;
                font-size: 12px; } } } }

.field_volum {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        &:first-child {
            min-width: 30%;
            flex: 1; } } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $n4;
        transition: fill .2s; } }
.dropdown {}
//    background: $n2
.dropdownHead {
   background: $n2;
   border: 2px solid transparent;
   @include dark {
        border-color: $n6;
        background: $n6;
        color: $n2; } }
//    border-radius: 12px
.field_error {
    padding-left: 4px;
    transition: all .2s;
    .error {
        transition: all .2s;
        color: $p3;
        font-size: 14px;
        padding-top: 4px; } }
.html {
    width: 100%;
    overflow: scroll; }
