@import "../../styles/helpers";

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 48px;
    background: $n1;
    @include m {
        padding: 24px; }
    @include dark {
        background: $n7; } }

.wrapper {
    max-width: 380px;
    @include m {
        max-width: 100%; } }
.image {
    width: 100%;
    min-height: 200px;
    object-fit: cover; }
.logo {
    display: inline-block;
    width: 100%;
    margin-bottom: 32px;
    img {
        height: 51px;
        width: 100%; } }

.title {
    margin-bottom: 32px; }

.head {
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 2px solid $n3;
    @include dark {
        border-color: $n6; } }

.subtitle {
    margin-bottom: 20px;
    @include body2-s; }
.sublink {
    margin-bottom: 12px!important;
    @include body2-s;
    color: $p1; }

.btns {
    display: flex;
    margin: 0 -4px;
    .button {
        flex: 0 0 calc(50% - 8px);
        width: calc(50% - 8px);
        margin: 0 4px;
        padding: 0 16px;
        img {
            margin-right: 10px; } } }

.body {
    .button {
        width: 100%; } }

.field {
    margin-bottom: 12px; }

.note {
    margin-top: 32px;
    @include body2-s;
    color: $shades1; }

.info {
    margin-top: 32px;
    @include caption1;
    color: $n4;
    a {
        font-weight: 700;
        color: $n7;
        transition: color .2s;
        @include dark {
            color: $n1; }
        &:hover {
            color: $p1; } } }
.close {
    position: fixed;
    top: 0;
    right: 5%;
    svg {
        font-size: 1.4rem;
        @include dark {
            fill: #fff; } } }
