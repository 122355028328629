@import "../../styles/helpers";
.field_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        &:first-child {
            // margin-top: 0.8px
            flex: 0 0 50%; } } }
.field_label {
    margin-top: 12px;
    .label {
        margin-bottom: 14px;
        @include base2;
        color: $n5;
        @include dark {
            color: $n3; } }
    .tooltip {
        position: relative;
        top: -1px;
        svg {
            fill: $shades1; } } }
.dropdownHead {
   background: $n2;
   border: 2px solid transparent;
   @include dark {
        border-color: $n6;
        background: $n6;
        color: $n2; } }
//    border-radius: 12px
.field_error {
    padding-left: 4px;
    transition: all .2s;
    .error {
        transition: all .2s;
        color: $p3;
        font-size: 14px;
        padding-top: 4px; } }
