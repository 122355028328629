@import "styles/helpers";

.col {
    position: relative;
    display: table-cell;
    vertical-align: top;
    padding: 16px 12px;
    color: $n7; }

.item {
    display: flex;
    align-items: center;
    // cursor: pointer
    transition: color .2s;
    // &:hover
 }    //     color: $p1


.avatar {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.admin {
    position: relative;
    background: #fcfcfc;
    @include dark {
        background: #1b1d1f; }
    border-radius: 16px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 100%;
    @include m {
        height: 400px; }
    display: flex;
    padding: 20px 12px;
    .user {
        @include dark {
            color: $n1; } }
    .link {
        display: flex;
        align-items: center;
        svg {
            fill: $p1;
            margin-right: 4px; }
        @include dark {
            color: $n1; } }
    .date {
        color: $n4; }
    .logo {
        width: 100%;
        display: flex;
        align-items: center;
        transition: color .2s;
        @include m {
            justify-content: center;
            flex-direction: column;
            text-align: center; } }

    .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
            width: auto; }
        @include m {
            flex-wrap: wrap;
            justify-content: center; }
        &:first-child {
                @include m {
                    flex: 0 0 calc(100%); } }
        .details {
            width: 50%;
            @include m {
                flex: 0 0 33%; } } }
    .avatar {
        flex-shrink: 0;
        width: 88px;
        height: 88px;
        margin-right: 12px;
        border-radius: 12%;
        overflow: hidden;
        object-fit: cover;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } } }
