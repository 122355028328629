@import "../../styles/helpers";

.tooltipWpapper {
    padding: 3px 12px !important;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000;
    font-size: 12px !important;
    font-weight: 600;
    z-index: 4000;
    &::after {
        z-index: 0;
        display: none; }
    &::before {
        z-index: 0;
        display: none; } }
