@import "../../../styles/helpers";

.title {
    margin-bottom: 24px; }

.info {
    margin-bottom: 12px;
    @include title1-s; }

.preview {
    img {
        width: 100%;
        border-radius: 8px; } }

.foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $n3;
    @include dark {
        border-color: $n6; }
    .button {
        background: $shades1;
        span {
            color: $n1; }
        &:first-child {
            background: $p3;
            margin-right: 24px; } } }
