@import "../../styles/helpers";

.field_volum {
    // margin-top: 12px
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        &:first-child {
            min-width: 40%;
            flex: 1; } }
    @include m {
        flex-direction: column;
        div {
            width: 100%;
            flex: 0 0 100%;
            margin-bottom: 6px; } } }

.field_label {
    margin-top: 12px;
    .label {
        margin-bottom: 14px;
        @include base2;
        color: $n5;
        @include dark {
            color: $n3; } }
    .tooltip {
        position: relative;
        top: -1px;
        svg {
            fill: $shades1; } } }
.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $n4;
        transition: fill .2s; } }
.dropdown {}
//    background: $n2
.dropdownHead {
   background: $n2;
   border: 2px solid transparent;
   @include dark {
        border-color: $n6;
        background: $n6;
        color: $n2; } }
//    border-radius: 12px
.field_error {
    padding-left: 4px;
    transition: all .2s;
    .error {
        transition: all .2s;
        color: $p3;
        font-size: 14px;
        padding-top: 4px; } }
