@import "../../../../styles/helpers";

.title {
    margin-bottom: 24px; }

.info {
    margin-bottom: 12px;
    @include title1-s; }

.preview {
    img {
        width: 100%;
        border-radius: 8px; } }
.body {
    height: 300px;
    min-height: 30%;
    max-height: 40%;
    overflow-y: scroll;
    border-top: 1px solid $n3;
    padding-top: 24px;
    // background: #F1F3F8
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    @include dark {
        color: #e7e7e7; }
    .item_right,
    .item {
        margin-bottom: 18px;
        width: 100%;
        display: flex;
        align-items: start;
        flex-direction: row;
        justify-content: flex-start;
        .icon {
            margin-right: 6px;
            img {
                border-radius: 50%;
                height: 40px;
                width: 40px; } }
        .messages {
            width: 100%;
            .message {
                font-size: 16px; }
            .name {
                display: flex;
                justify-content: space-between; }
            .file {
                img {
                    max-width: 300px;
                    @include m {
                        max-width: 200px; } } }

            .time {
                font-size: 12px;
                color: #747474;
                @include dark {
                    color: #747474; } } } }
    .item_right {
        flex-direction: row-reverse;
        text-align: right;
        .icon {
            margin-left: 6px; }

        .messages {
            .name {
                flex-direction: row-reverse;
                justify-content: space-between; } } } }
.text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        margin-left: 8px;
        margin-right: 12px; } }
.foot {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 0px;
    padding-top: 4px;
    border-top: 1px solid $n3;
    @include dark {
        border-color: $n6; }
    .textarea {
        width: 90%;
        height: 52px;
        padding: 8px 10px;
        border-radius: 0px;
        border: 2px solid transparent;
        background: transparent;
        border-bottom: 1px solid $n4;
        @include inter;
        @include base1-s;
        color: $n7;
        transition: all .2s;
        @include dark {
            border-color: $n6;
            background: $n6;
            color: $n1; }
        @include placeholder {
            color: $n4;
            @include dark {
                color: $shades1; } } } }

.field {
    margin-top: 8px; }

.field_flex {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        &:first-child {
            // margin-top: 0.8px
            flex: 0 0 50%; } } }
.field_volum {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
        &:first-child {
            min-width: 30%;
            flex: 1; } } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $n4;
        transition: fill .2s; } }
.dropdown {}
//    background: $n2
.dropdownHead {
    background: $n2;
    border: 2px solid transparent;
    @include dark {
        border-color: $n6;
        background: $n6;
        color: $n2; } }
