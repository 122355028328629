@import "../../styles/helpers";
.section {
    display: flex;
    justify-content: center;
    padding-top: 20%; }
.card {
    width: 50%;
    min-width: 700px;
    height: 200px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    @include a {
        flex-direction: column;
        justify-content: center;
        height: 400px;
        width: 100%;
        min-width: 200px;
        max-width: 300px; }

    .info {
        min-width: 60%;
        max-width: 60%;
        @include a {
            max-width: 100%;
            min-width: 100%; }

        .h1 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 48px;
            letter-spacing: -0.03em; }
        .h2 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -0.03em;
            color: #6FFF86;
            margin-top: -24px; }
        .h3 {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03em;
            margin-top: 0px; }
        .status {
            padding-bottom: 10px; } } }
.image {
    @include a {
        margin-top: 20px;
        overflow: hidden;
        display: flex;
        justify-content: center; }
    img {
        margin-top: -50px;
        margin-left: 0px;
        @include a {
            margin-top:  0px;
            margin-left: 0px0; } } }
