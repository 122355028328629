@import "../../../../styles/helpers";

.title {
    margin-bottom: 24px; }

.info {
    margin-bottom: 12px;
    @include title1-s; }

.preview {
    img {
        width: 100%;
        border-radius: 8px; } }

.foot {
    display: flex;
    align-items: center;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $n3;
    @include dark {
        border-color: $n6; } }

.field {
    margin-top: 8px; }
.field_flex {
    display: flex;
    justify-content: left;
    align-items: center;
    label {
        span {
            span {
                padding-left: 6px;
                padding-right: 6px;
                font-size: 12px; } } } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $n4;
        transition: fill .2s; } }
