[class^="title"] {
    position: relative;
    display: inline-block;
    padding-left: 32px;
    @include title1-s;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 16px;
        height: 32px;
        transform: translateY(-50%);
        border-radius: 4px; } }

.title-red {
    &:before {
        background: $s1; } }

.title-blue {
    &:before {
        background: #B1E5FC; } }

.title-purple {
    &:before {
        background: $s2; } }

.title-yellow {
    &:before {
        background: $s5; } }

.title-green {
    &:before {
        background: $s4; } }

[class^="text"] {
    position: relative;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    line-height: (32/20);
    // letter-spacing: -.02em
    font-family: 'Inter', sans-serif; }

.text-red {
    color: $s1; }
.link {
    cursor: pointer; }
.text-blue {
    color: #B1E5FC; }

.text-purple {
    color: $s2; }

.text-yellow {
    color: $s5; }

.text-green {
    color: $p2; }
