@import "../../../styles/helpers";

.head {
    margin-bottom: 44px;
    @include t {
        margin-bottom: 32px; }
    @include m {
        flex-wrap: wrap;
        margin-bottom: 24px;
        .title {
            width: 100%;
            margin: 0 0 16px; } } }

.button {
    margin-left: 16px; }

.table {
    display: table;
    width: 100%;
    @include t {
        display: block; } }

.row {
    display: table-row;
    @include t {
        display: none; } }

.col {
    display: table-cell;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $n3;
    @include caption2;
    font-weight: 500;
    color: $n4;
    @include dark {
        border-color: $n6; }
    &:not(:last-child) {
        padding-right: 24px; } }

.foot {
    margin-top: 20px;
    text-align: center;
    @include t {
        margin-top: 0; }
    .button {
        span {
            // color: $n1
            @include dark {
                color: $n1; } }

        .loader {
            transform: scale(.8);
            margin: 0 18px 0 8px; } } }

.outer {
    max-width: 560px; }
.filters {
    margin-right: 20px; }

.back {
    color: $n7 !important;
    svg {
        fill: $n7 !important; }
    @include dark {
        color: $n3 !important;
        svg {
            fill: $n3 !important; } } }
