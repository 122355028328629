@import "../../../../styles/helpers";

.title {
    margin-bottom: 24px; }

.list {
    display: flex;
    margin-bottom: 24px; }

.avatar {
    position: relative;
    width: 48px;
    height: 48px;
    & > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; }
    &:not(:last-child) {
        margin-right: 24px; } }

.social {
    position: absolute;
    right: -4px;
    bottom: -4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $n1;
    background: $p1;
    @include dark {
        border-color: $n7; }
    svg {
        fill: $n1; } }

.textarea {
    width: 100%;
    height: 132px;
    background: none;
    border: none;
    box-shadow: none;
    resize: none;
    @include title1-s;
    color: $n7;
    @include dark {
        color: $n1; }
    &::placeholder {
        color: $shades3;
        @include dark {
            color: $shades1; } } }

.info {
    margin-bottom: 12px;
    @include title1-s; }

.preview {
    img {
        width: 100%;
        border-radius: 8px; } }

.foot {
    display: flex;
    align-items: center;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $n3;
    @include dark {
        border-color: $n6; } }

.files {
    display: flex;
    margin-right: auto; }

.file {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    input {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 120px;
        opacity: 0; }
    input:hover + .icon {
        background: $n3;
        cursor: pointer;
        svg {
            fill: $n7; }
        @include dark {
            background: $n6;
            svg {
                fill: $n1; } } }
    &.active {
        .icon {
            background: $n3; } }
    &:not(:last-child) {
        margin-right: 16px; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $n4;
        transition: fill .2s; } }
