@import "../../styles/helpers";

.chart {
    width: auto;
    height: 60px;
    @include m {
        height: 60px; } }

.people {
    img {
        width: 60px;
        height: 60px;
        // border-radius: 50%
        @include m {
            height: 60px; } }
    div {
        position: absolute;
        margin: -35px 0px 0px 15px;
        // right: 30px
        z-index: 1;
        // background: #ffffff
        // padding: 6px
        width: 30px!important;
        height: 30px!important;
        // border-radius: 50%
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include dark {
            // background: $n5
            color: $n1; } } }
.day {
    img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        @include m {
            height: 60px; } }
    div {
        position: absolute;
        margin: -32px 0px 0px 15px;
        // right: 30px
        z-index: 1;
        background: transparent;
        // padding: 6px
        width: 30px!important;
        height: 30px!important;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @include dark {
            color: $n1; } } }
