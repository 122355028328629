@import "../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(100%);
        width: calc(100%);
        padding-right: 0px; } }

.card {
    padding: 0px;
    background: #FCFCFC;
    border-radius: 14px;
    &:not(:last-child) {
        margin-bottom: 20px; } }
.card_recharge {
    padding: 12px;
    margin-left: 12px;
    @include dark {
        background: $n6; }
    border-radius: 14px;
    &:not(:last-child) {
        margin-bottom: 20px; }
    .list {
        .package {
            .content {
                margin-top: 24px;
                color: $n4;
                b {
                    color: $n8;
                    @include dark {
                        color: $n1; } } }
            .wallet {
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;
                @include m {
                    width: 100%; }
                .ussd {
                    cursor: pointer;
                    min-width: 80px;
                    text-align: center;
                    background-color: $n3;
                    padding: 12px;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: -0.02em;
                    color: $n4;
                    border-radius: 12px;
                    &:hover {
                        background-color: $n4;
                        color: $n3; } }
                .active {
                    background-color: $n4;
                    color: $n3; }
                .input {
                    width: 100%;
                    margin-right: 12px; }
                .btn {
                    cursor: pointer;
                    height: auto;
                    padding: 12px;
 } } } } }                    // color: $n3
