@import "../../../../styles/helpers";

.title {
    margin-bottom: 24px; }

.info {
    margin-bottom: 12px;
    @include title1-s; }

.preview {
    img {
        width: 100%;
        border-radius: 8px; } }

.foot {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 24px;
    padding-top: 24px;
    // border-top: 1px solid $n3
    // +dark
 }    //     border-color: $n6
.body {
    width: 100%;
    overflow: scroll;
    .filters {
        display: none; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $n4;
        transition: fill .2s; } }

