@import "../../../../styles/helpers";
.title {
    margin-bottom: 24px; }
.foot {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $n3;
    @include dark {
        border-color: $n6; } }
.head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 31px;
    @include a {
        flex-direction: column; }

    .info {
        font-size: 20px;
        font-weight: 700; }
    .filters {
        display: flex;
        justify-content: flex-end;
        @include a {
            justify-content: flex-start;
            margin-top: 5px; }

        .filter {
            margin-right: 20px;
            .title {
                color: $n4;
                margin-right: 5px; }
            .select {
                color: $n4;
                border-radius: 8px;
                background: $n1;
                width: 58px;
                @include dark {
                    background: $n8; } } } } }
.body {
    display: flex;
    flex-wrap: wrap; }
.card {
    // flex: 1
    background: $n1;
    @include dark {
        background: $n7; }
    flex: 0 0 calc(50% - 20px);
    @include t {
        flex: 0 0 calc(100%); }

    margin-bottom: 20px!important;
    margin-right: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 31px;
    padding-right: 0px;
    border-radius: 15px 20px 20px 15px;
    @include m {
        flex: 0 0 calc(100%);
        border-radius: 15px 20px 20px 15px;
        padding-left: 0px; }
 }    // max-height: 298px
.item {
    display: flex;
    justify-content: space-between;
    @include m {
        justify-content: space-between;
        flex-direction: column; } }
.left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    padding-top: 24px;
    padding-bottom: 27px;
    @include m {
        padding-left: 23px; }
    .top {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // +m
        //     flex-direction: column
        //     justify-content: center
        .win {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.02em;
            color: #FFBC99;
            margin-top: 12px; }
        .win_number {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 70px;
            line-height: 70px;
            letter-spacing: -0.02em;
            margin-bottom: 4px; }
        .type {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.02em; } } }

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #dcdcdc;
    @include dark {
        background: #302D2D; }
    border-radius: 0px 20px 20px 0px;
    @include m {
        border-radius: 0px 0px 20px 20px;
        min-height: 64px; }
    .enter {
        color: #FF6A55;
        transform: rotate(-90deg);
        @include m {
            transform: rotate(0deg); } } }

.footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px; }
.title {
    // flex-grow: 1
    // padding-right: 16px
    transition: color .2s; }
.stage {
    color: $n4;
    margin-top: 9px; }

.preview {
    flex-shrink: 0;
    width: 170px;
    height: 170px;
    margin-right: 27px;
    border-radius: 18px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
.btnBox {
    display: flex;
    justify-content: center;
    margin-top: 8px; }
.button {
    max-width: 200px;
    margin-left: 12px;
    &_gray {
        background: $n4; } }
